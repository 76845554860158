<template>
  <section class="invoice-preview-wrapper">
    <b-row
        v-if="order"
        class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
          cols="12"
          xl="9"
          md="8"
      >
        <b-card
            v-if="$route.name == 'Order edit'"
            no-body
            class="invoice-preview-card position-relative"
        >
          <validation-observer #default="{handleSubmit}">
            <b-form @submit.prevent="handleSubmit(submitOrder)">
              <form-order
                  :order="order"
                  @downloadOrder="downloadOrder($event)"
              />
              <button ref="submitOrderFormRef"
                      class="d-none"></button>
            </b-form>
          </validation-observer>

        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
          cols="12"
          md="4"
          xl="3"
          class="offer-actions"
      >
        <buttons-col
            :order="order"
            @submitForm="submitForm"
        />
      </b-col>

    </b-row>

  </section>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { useOrders } from './useOrders'
import { ValidationObserver } from 'vee-validate'
import { mixinOrders } from './mixinOrders'

import useAPI from '@/utils/useAPI'
import store from '@/store'
import router from '@/router'
import FormOrder from '@/components/form/order/Order'
import ButtonsCol from './components/_buttons_col'

export default {
  components: {
    ValidationObserver,
    FormOrder,
    ButtonsCol
  },
  mixins: [mixinOrders],
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const submitOrderFormRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const order = computed(() => {
      return store.getters['order/getOrder'](router.history.current.params.orderId)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    // const {
    //   fetchArticles,
    //   fetchUsers,
    //   fetchCompanies,
    //   fetchOrders,
    //   fetchPaymentMethods,
    //   fetchPaymentTerms,
    //   fetchVats
    // } = useAPI()

    const { submitValidatedOrder } = useOrders()

    const submitForm = () => {
      submitOrderFormRef.value.click()
    }

    const submitOrder = () => {
      submitValidatedOrder(order.value)
          .then(response => {
            router.push({ name: 'Order view', params: { orderId: order.value.id } })
          })

    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    if (order.value && order.value.billNumber != null) {
      router.push({ name: 'Order view', params: { orderId: order.value.id } })
    }

    return {
      // Components

      // Data
      submitOrderFormRef,

      // Computed
      order,

      // Methods
      submitForm,
      submitOrder
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>